import React from "react"
// import { navigate } from 'gatsby';
import Button from "../../../../../Button"
import SvgIcon from "../../../../../SvgIcon"
import BudsCoverText from "../../../../../TextBudStage/"
import styles from "./menu.module.scss"
// import scrollTo from 'gatsby-plugin-smoothscroll';

const Menu = props => {
  const { onOpen, menuIsOpen, handleRedirect } = props
  const handleMenu = () => {
    onOpen(false)
  }
  const handleTyC = () => {
    window.location.replace("/budstage/tyc")
  }

  const handleRedirectMobile = id => {
    handleRedirect(id)
    handleMenu()
  }

  return (
    <div
      className={`${styles.menu} ${
        menuIsOpen ? styles.showMenu : styles.hideMenu
      }`}
    >
      <div className={styles.menuHeader}>
        <SvgIcon name="bud-icon" size="34.579vw" />
        <SvgIcon name="close-icon" size="5.128vw" onClick={handleMenu} />
      </div>
      <div className={styles.menuContent}>
        <ul>
          <li onClick={() => window.location.replace("/budstage/temas")}>
            BUD STAGE
          </li>
          <li onClick={() => handleRedirectMobile("steps")}>CÓMO FUNCIONA</li>
          <li onClick={() => handleRedirectMobile("awards")}>PREMIOS</li>
          <li onClick={() => handleRedirectMobile("jury")}>JURADO</li>
        </ul>
        <Button
          label="PARTICIPAR"
          onClick={() => window.location.replace("/budstage/participate")}
        />
        <BudsCoverText className={styles.menuBudText} orientation="vertical" />
      </div>
      <div className={styles.menuBottom}>
        <SvgIcon
          name="we-brew-icon"
          size="18.931vw"
          className={styles.weBrewIcon}
        />
        <a onClick={handleTyC}>VER BASES Y CONDICIONES</a>
      </div>
    </div>
  )
}

export default Menu
