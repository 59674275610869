export const IsMobileDevice = () => {
  //return if device is mobile
  return (
    typeof window.orientation !== "undefined" ||
    navigator.userAgent.indexOf("IEMobile") !== -1
  )
}

//validate mail
export const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

export const calculateAge = birthday => {
  let ageDate = new Date(Date.now() - birthday.getTime())
  if (Math.abs(ageDate.getUTCFullYear() - 1970) >= 18) return true
  return false
}

export const calculateAgeNew = birthday => {
  let ageDate = new Date(Date.now() - birthday.getTime())
  let today = new Date()
  if (
    Math.abs(ageDate.getUTCFullYear() - 1970) >= 18 &&
    birthday.getMonth() < today.getMonth()
  )
    return true
  return false
}

export const calculateDate = birthday => {
  let today = new Date()
  if (birthday.getDate() <= today.getDate()) return true
  return false
}

export const setUserData = data => {
  const dataToSet = JSON.stringify(data);
  localStorage.setItem('userData', dataToSet);
};

export const getUserData = () => {
  return JSON.parse(localStorage.getItem('userData')) || {};
};
