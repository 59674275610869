import React, { useState } from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import styles from './layout.module.scss';

const Layout = (props) => {

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { children, type, noFooter, noBudIcon, onlyText, footerType } = props;
  const stylesOpenMenu = menuIsOpen ? styles.openMenu : '';

  return (
    <div className={`${styles.layout}`}>
      <Navbar
        type={type}
        noBudIcon={noBudIcon}
        menuIsOpen={menuIsOpen}
        setMenuIsOpen={setMenuIsOpen}
      />
      <div className={`${styles.layoutContent} ${stylesOpenMenu}`}>
        {children}
      </div>
      {!noFooter && <Footer type={footerType} onlyText={onlyText} />}
    </div>
  )

}

export default Layout;
