import React, { useEffect, useState, useRef } from "react"
// import { navigate } from "gatsby"
import { bool, object, func } from "prop-types"
import ReactPlayer from "react-player"
import Button from "../Button"
import SvgIcon from "../SvgIcon"
import Likes from "./components/likes"
import styles from "./cardVideo.module.scss"
import { IsMobileDevice } from "../../../utils"
import preview from "../../../images/BudStage/Preview.jpg"

const CardVideo = ({
  toVote,
  toPlay,
  data,
  handleClick,
  className,
  shareType,
}) => {
  const [isMobile, setIsMobile] = useState()

  useEffect(() => {
    setIsMobile(IsMobileDevice())
  })
  if (!data.videoId) return null

  const shareByType = {
    primary: "",
    secondary: "2.986vw",
  }
  const shareByTypeMobile = {
    primary: "",
    secondary: "11.026vw",
  }

  const handleShare = (e, coverId) => {
    e.preventDefault()
    e.stopPropagation()
    window.location.replace(`/budstage/share/?coverId=${coverId}`)
  }

  const type = toPlay
    ? styles.singleCover
    : toVote
    ? styles.toVote
    : styles.covers
  return (
    <div
      className={`${styles.card} ${type} ${className}`}
      onClick={e => handleClick(data.id, e)}
    >
      <div className={styles.videoContent}>
        {toPlay ? (
          <ReactPlayer
            url={`${data.videoId}`}
            controls
            width="100%"
            height="auto"
          />
        ) : (
          <>
            <div className={styles.imgContainer}>
              {/* <img src={data.videoImg || preview} alt="cover img" /> */}
              <ReactPlayer
                url={`${data.videoId}`}
                width="100%"
                height="100%"
                light = {true}
               
              />
              <SvgIcon
                name="play-icon"
                size={isMobile ? "14.479vw" : "4.097vw"}
                className={styles.play}
              />
              <div className={styles.fade}></div>
            </div>
          </>
        )}
        <Likes coverId={data.id} likes={data.votes || 0} />
      </div>
      <div className={styles.cardInfo}>
        <div className={styles.info}>
          {/* <p>{data.artist}</p> */}
          <h1>{data.song}</h1>
          <h6>
            <span>x</span>
            {data.userName}
          </h6>
        </div>
        <div className={styles.vote}>
          <SvgIcon
            onClick={e => handleShare(e, data.id)}
            name="share-icon"
            size={
              isMobile ? shareByTypeMobile[shareType] : shareByType[shareType]
            }
            className={styles.share}
          />
          {/*{toPlay && (
            <Button
              onClick={e => handleClick(data.id, e)}
              type="primary"
              label="VOTAR"
              size="large"
              iconSide="left"
              icon={
                <SvgIcon
                  name="heart-icon"
                  size={isMobile ? "5.438vw" : "1.458vw"}
                />
              }
            />
          )}*/}
        </div>
      </div>
    </div>
  )
}

CardVideo.propTypes = {
  toPlay: bool,
  toVote: bool,
  data: object,
  handleClick: func,
}

CardVideo.defaultProps = {
  toPlay: false,
  toVote: false,
  data: {},
  handleClick: () => {},
}

export default CardVideo
