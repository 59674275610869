import React, { useState, useEffect } from "react"
import SvgIcon from "../../../SvgIcon"
import Button from "../../../Button"
import styles from "./navbar.module.scss"
import { IsMobileDevice } from "../../../../../utils"
import Menu from "./components/Menu"

const Navbar = props => {
  const { type, noBudIcon, menuIsOpen, setMenuIsOpen } = props
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(IsMobileDevice())
  }, [])

  const onClickHandler = () => {
    window.location.replace("/budstage/participate")
  }
  const closePage = () => {
    window.location.replace("/budstage")
  }

  const redirectBudweiser = () => {
    window.location.replace("/")
  }

  const handleCloseMenu = () => {
    setMenuIsOpen(!menuIsOpen)
  }

  const handleRedirect = id => {
    const element = document.getElementById(id)
    element
      ? window.location.replace(`#${id}`)
      : window.location.replace(`/budstage#${id}`)
  }

  if (type === "secondary") {
    return (
      <div className={styles.secondaryHeader}>
        {!isMobile && <SvgIcon name="bud-whiteform-icon" size="2.995vw" />}
        {!noBudIcon && (
          <SvgIcon name="bud-icon" size={isMobile ? "34.579vw" : "9.365vw"} />
        )}
        <div className={styles.closeIcon}>
          <span>cerrar</span>
          <SvgIcon
            name="close-icon"
            size={isMobile ? "5.128vw" : "1.458vw"}
            onClick={closePage}
          />
        </div>
      </div>
    )
  }

  return (
    <nav className={styles.navbar}>
      {isMobile ? (
        <div className={styles.wrapper}>
          <a onClick={redirectBudweiser}>
            <SvgIcon name="bud-icon" size="34.579vw" key="bud-icon-mobile" />
          </a>
          <div style={{ marginBottom: "20px" }}>
            <SvgIcon
              name="menu-burguer-icon"
              size="8.128vw"
              onClick={handleCloseMenu}
            />
          </div>

          <Menu
            menuIsOpen={menuIsOpen}
            onOpen={setMenuIsOpen}
            handleRedirect={handleRedirect}
          />
        </div>
      ) : (
        <div className={styles.wrapper}>
          <a onClick={redirectBudweiser}>
            <SvgIcon name="bud-icon" size="9.365vw" />
          </a>
          <ul>
            <li>
              <a onClick={() => window.location.replace("/budstage/temas")}>
                BUD STAGE
              </a>
            </li>
            <li>
              <a onClick={() => handleRedirect("steps")}>CÓMO FUNCIONA</a>
            </li>
            <li>
              <a onClick={() => handleRedirect("awards")}>PREMIOS</a>
            </li>
            <li>
              <a onClick={() => handleRedirect("jury")}>JURADO</a>
            </li>
          </ul>
          <div className={styles.buttonContainer}>
            {/*<button onClick={onClickHandler} >PARTICIPAR</button>*/}
          </div>
        </div>
      )}
    </nav>
  )
}

export default Navbar
